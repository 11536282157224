import * as React from "react"
import * as styles from "./index.module.scss"
import {Navigation, Pagination, Scrollbar, A11y} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css"

const Slider = ({children}) => {
    return (
        <div className={styles.parent}>

            <Swiper
                pagination={{
                    el: ".swiper-nav2",
                    type: "bullets",
                    clickable: true,
                }}
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
            >
                {children.map(child => {
                    return (
                        <SwiperSlide key={child.img_id}>
                            {child}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}
export default Slider;