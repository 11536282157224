import * as React from "react"
import * as styles from "./index.module.scss"
import Slider from "../components/organism/slider"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FloatTube from "../components/atom/floatTube"
import LongButton from "../components/atom/longButton";
import ImageC from "../components/atom/ImageC";
import IconNumberText from "../components/atom/iconNumberText";
import HoverCard from "../components/molecule/hoverCard";
import {IconRegenschirm} from "../data/icon"
import {IconTm} from "../data/icon"
import {IconAlu} from "../data/icon"
import {IconFocused} from "../data/icon"
import {IconSolutions} from "../data/icon"
import {Icon70Years} from "../data/icon"

const IndexPage = () => (
    <Layout>
        <Seo title="Home"/>
        <Slider>
            <div className={styles.sliderContainer}>
                <div className={styles.sliderTextContainer}>

                    <h2 className={styles.h2}>
                        We have committed <br className={styles.br}/>
                        to the Science Based <br className={styles.br}/>
                        Targets initiative <br className={styles.br}/>
                    </h2>
                    <LongButton to="/">
                        Explore More
                    </LongButton>
                </div>
                <ImageC className={styles.imageSlider} name="Frontpage-SBTi"/>
            </div>
            <div className={styles.sliderContainer}>
                <div className={styles.sliderTextContainer}>

                    <h2 className={styles.h2}>
                        We have committed <br className={styles.br}/>
                        to the Science Based <br className={styles.br}/>
                        Targets initiative <br className={styles.br}/>
                    </h2>
                    <LongButton to="/">
                        Explore More
                    </LongButton>
                </div>
                <ImageC className={styles.imageSlider} name="Frontpage-SBTi"/>
            </div>
        </Slider>
        <FloatTube/>
        <div className={styles.section}>
            <div className={styles.text}>
                <h2 className={styles.h2}>
                    Three good <br className={styles.br}/>
                    reasons for <br className={styles.br}/>
                    aluminium tubes <br className={styles.br}/>
                </h2>
                <p className={styles.p}>
                    <strong>Aluminium tubes made by the experts</strong>
                    protect their contents and the environment – and offer a wealth of added
                    value for you and your customers.
                </p>
            </div>
            <div className={styles.cardContainer}>
                <HoverCard text="Protection for your"
                           boldText="product"
                           bgColor="color0"
                           imageName={"Protection-product"}
                           icon={<IconRegenschirm/>}/>
                <HoverCard text="Protection for our"
                           boldText="planet"
                           bgColor="color1"
                           imageName={"blaetter"}
                           icon={<IconAlu/>}/>
                <HoverCard text="Protection for your"
                           boldText="brand"
                           bgColor="color2"
                           imageName={"woman-child-beach"}
                           icon={<IconTm/>}/>

            </div>
        </div>
        <div className={styles.section}>
            <div className={styles.text}>
                <h2 className={styles.h2}>
                    Three good reasons <br className={styles.br}/>
                    for TUBEX <br className={styles.br}/>
                    aluminium tubes <br className={styles.br}/>
                </h2>
                <p className={styles.p}>
                    <strong>Innovative specialists with over 70 years of experience</strong>
                    Our expertise spans more than seven decades, we invest heavily in our production
                    capacities and we’re constantly innovating. This makes us the ideal partner
                    for any company looking to attract both current and future generations of consumers.
                </p>
            </div>
            <div className={styles.cardContainer}>
                <HoverCard text="We are"
                           boldText="specialists"
                           bgColor="color3"
                           imageName={"wir-sind-spezialisiert"}
                           icon={<IconFocused/>}/>
                <HoverCard text="We deliver"
                           boldText="solutions"
                           bgColor="color4"
                           imageName={"Tubex-Wir-liefern-Loesungen"}
                           icon={<IconSolutions/>}/>
                <HoverCard text="We have"
                           boldText="know-how"
                           bgColor="color5"
                           imageName={"Tubex-Know-How"}
                           icon={<Icon70Years/>}/>
            </div>
        </div>
        <div className={styles.section}>
            <div className={styles.text}>
                <h2 className={styles.h2}>
                    Four sectors <br className={styles.br}/>
                    that rely on <br className={styles.br}/>
                    our expertise <br className={styles.br}/>
                </h2>
                <p className={styles.p}>
                    The COSMETICS, FOOD, PHARMA and INDUSTRIAL
                    sectors are our core business. Our deep understanding
                    of these sectors and their needs means that we can provide
                    them with the very best solutions. Browse each sector for more details.
                </p>
                <LongButton>
                    learn more
                </LongButton>
            </div>
        </div>
        <div className={styles.sectionGalance + " " + styles.section}>
            <h2 className={styles.h2}>
                At a Galance
            </h2>
            <div className={styles.iconContainer}>
                <IconNumberText iconName="IconKaufen" height="100px" number="~650" text="active customers"/>
                <IconNumberText iconName="IconMarkt" height="100px" number="76" text="countries worldwide"/>
                <IconNumberText iconName="IconSeven" height="100px" number="7" text="locations worldwide"/>
                <IconNumberText iconName="IconProduktion"
                                height="100px"
                                number="36"
                                text="production lines"/>
                <IconNumberText iconName="IconMitarbeiter" height="100px" number="1000" text="employees"/>
                <IconNumberText iconName="Icon70Years" height="100px" number="70" text="years of know-how"/>
            </div>
            <LongButton>
                learn more
            </LongButton>
        </div>

        <div className={styles.sectionImage + " " + styles.section}>
            <div className={styles.text}>
                <h2 className={styles.h2}>
                    Endless possibilities
                </h2>
                <p className={styles.p}>
                    Discover a world of new possibilities for the size,
                    look and feel of your aluminium tubes. From the right size and shape,
                    to the colour of lacquer and the style of our practical and secure closures,
                    we can create the perfect packaging for your product.
                </p>
                <LongButton>
                    learn more
                </LongButton>
            </div>
            <figure>
                <ImageC name="tube-collection-tubex" alt="tube collection tubex"/>
            </figure>
        </div>
        <Slider>
            <div className={styles.sliderContainer + " " + styles.sliderContainer2}>
                <div className={styles.sliderTextContainer}>
                    <p className={styles.bigHeader}>
                        Pharma
                    </p>
                    <p className={styles.littleBigHeader}>
                        by Axonpack
                    </p>
                    <p className={styles.p}>
                        Aluminium’s outstanding properties – as a barrier against air, light, moisture and microorganisms – mean that our tubes are an excellent choice for pharmaceutical products. In particular, they offer optimal protection for highly sensitive drugs and help extend their shelf life.

                    </p>
                    <LongButton to="/">
                        Explore More
                    </LongButton>
                </div>
                <ImageC className={styles.imageSlider} imageName="pharma"/>
            </div>
            <div className={styles.sliderContainer + " " + styles.sliderContainer2}>
                <div className={styles.sliderTextContainer}>
                    <p className={styles.bigHeader}>
                        Food
                    </p>
                    <p className={styles.littleBigHeader}>
                        by Axonpack
                    </p>
                    <p className={styles.p}>
                        Aluminium is the first-choice packaging for many types of food. As well as protecting its contents from light and air, aluminium packaging is tasteless, preserves the flavour of the food and is ideal for sterilisation. What’s more, aluminium has superb environmental properties and offers a wide range of design options.


                    </p>
                    <LongButton to="/">
                        Explore More
                    </LongButton>
                </div>
                <ImageC className={styles.imageSlider} imageName="food"/>
            </div>
            <div className={styles.sliderContainer + " " + styles.sliderContainer2}>
                <div className={styles.sliderTextContainer}>
                    <p className={styles.bigHeader}>
                        Industry
                    </p>
                    <p className={styles.littleBigHeader}>
                        by Axonpack
                    </p>
                    <p className={styles.p}>
                        Aluminium tubes are light, slim, space-saving and so robust that they often require no outer packaging. As a result, you can transport more products with less packaging, which drastically reduces the impact on the environment. The high recycling rates of our aluminium tubes also helps our customers achieve positive life cycle assessments.
                    </p>
                    <LongButton to="/">
                        Explore More
                    </LongButton>
                </div>
                <ImageC className={styles.imageSlider} imageName="industry"/>
            </div>
            <div className={styles.sliderContainer + " " + styles.sliderContainer2}>
                <div className={styles.sliderTextContainer}>
                    <p className={styles.bigHeader}>
                        Cosmetics
                    </p>
                    <p className={styles.littleBigHeader}>
                        by Axonpack
                    </p>
                    <p className={styles.p}>
                        In the world of cosmetics, the “frills” play an especially important role in appealing to consumers and drawing attention to new products. Lightweight, robust and secure packaging is just as important as sophisticated design and sustainable materials.
                    </p>
                    <LongButton to="/">
                        Explore More
                    </LongButton>
                </div>
                <ImageC className={styles.imageSlider} imageName="cosmetics"/>
            </div>
        </Slider>
        <div className={styles.sectionImage + " " + styles.section}>
            <div className={styles.text}>
                <h2 className={styles.h2}>
                    What can we do <br className={styles.br}/>
                    for you and <br className={styles.br}/>
                    your business? <br className={styles.br}/>
                </h2>
                <p className={styles.p}>
                    Drop us a line or give us a call.
                    We’ll be happy to arrange a non-binding consultation to
                    tell you all about what aluminium packaging can do for you.
                </p>
                <LongButton>
                    get in touch
                </LongButton>
            </div>
        </div>
    </Layout>
)

export default IndexPage;