import * as React from "react"
import * as styles from "./index.module.scss"
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"

const FloatTube = ({}) => {
    const query = useStaticQuery(graphql`
        query tube {
            tube: allFile(
                filter: { name: {eq: "tube"}}
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const imageData = query.tube.nodes[0].childImageSharp.gatsbyImageData
    return (
        <div className={styles.tube}
        >
            <GatsbyImage
                image={imageData}
                alt="aliminium tube"
            />
        </div>
    )
}

export default FloatTube;