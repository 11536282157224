import * as React from "react"
import * as styles from "./index.module.scss"
import { Link } from "gatsby";

const LongButton = ({children,to}) => (
    <Link to={to}>
      <button className={styles.button}>
        <div className={styles.left}>
            {children}
        </div>
        <div className={styles.right}>
            >
        </div>
      </button>
    </Link>
)

export default LongButton;