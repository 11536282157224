// extracted by mini-css-extract-plugin
export var color0 = "index-module--color0--KD0La";
export var color1 = "index-module--color1--mEQaX";
export var color2 = "index-module--color2--Cxbef";
export var color3 = "index-module--color3--udM7Z";
export var color4 = "index-module--color4--wmc8W";
export var color5 = "index-module--color5--i++2b";
export var sliderContainer = "index-module--sliderContainer--wp-RL";
export var sliderContainer2 = "index-module--sliderContainer2--Kb-gZ";
export var sliderTextContainer = "index-module--sliderTextContainer--u913w";
export var bigHeader = "index-module--bigHeader--MQSUz";
export var littleBigHeader = "index-module--littleBigHeader--pdHBF";
export var imageSlider = "index-module--imageSlider--mkzXe";
export var text = "index-module--text--kuZYQ";
export var h2 = "index-module--h2--zqFQT";
export var p = "index-module--p--3lsjr";
export var br = "index-module--br--kH-3T";
export var galance = "index-module--galance--pZwmV";
export var iconContainer = "index-module--iconContainer--Yztua";
export var section = "index-module--section--SkAcW";
export var sectionGalance = "index-module--sectionGalance--OYNMS";
export var sectionImage = "index-module--sectionImage--Qhp-z";
export var bgcPrimary = "index-module--bgcPrimary--lRq-J";
export var cardContainer = "index-module--cardContainer--Dd5KV";