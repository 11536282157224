import * as React from "react"
import * as styles from "./index.module.scss"
import * as Icons from 'src/data/icon'

const IconNumberText = (props) => {
    const {number, text} = props;
    const Icon = Icons[props.iconName]
    return (
        <div className={styles.iconNumberText}>
            {<Icon {...props} className={styles.icon}/> || (`icon : ${props.iconName} not found !`)}
            <p className={styles.number}>
                {number}
            </p>
            <p>
                {text}
            </p>
        </div>
    )
}

export default IconNumberText;