import * as React from "react"
import * as styles from "./index.module.scss"
import ImageC from 'src/components/atom/ImageC'

const HoverCard = ({bgColor, text, imageName, boldText, icon, alt}) => {
    let Icon;
    Icon = React.cloneElement(icon, {...icon.props, className: styles.icon});
    return (
        <div className={styles.card + " " + bgColor}>
            <div className={styles.iconText}>
                {Icon}
                <p>{text}{' '}<strong className={styles.strong}>{boldText}</strong></p>
            </div>
            <ImageC className={styles.div_image} imageName={imageName} alt={alt}/>
            <div className={styles.div_blend}/>
        </div>
    )
}
export default HoverCard;