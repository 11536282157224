// extracted by mini-css-extract-plugin
export var color0 = "index-module--color0--dcDKv";
export var color1 = "index-module--color1--3r40k";
export var color2 = "index-module--color2--ctVb1";
export var color3 = "index-module--color3--oY4q1";
export var color4 = "index-module--color4--TUjfA";
export var color5 = "index-module--color5--NNdLK";
export var slider = "index-module--slider--x59nF";
export var card = "index-module--card--cfjHD";
export var icon = "index-module--icon--lVVOU";
export var iconText = "index-module--iconText--y78sE";
export var strong = "index-module--strong--mcAjk";
export var div_image = "index-module--div_image--ergxi";
export var div_blend = "index-module--div_blend--K5MDr";